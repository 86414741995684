<template lang="pug">
  div.layout-w
    Header
    NavBar
    .layout-router-v
      <router-view/>
</template>

<script>
import Header from "./Header.vue";
import NavBar from "./NavBar.vue";
export default {
  components: {
    Header,
    NavBar,
  },
};
</script>

<style lang="less" scoped>
.layout-w {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: url("../assets/image/bg.jpg");
  background-size: 100% 100%;
  .layout-router-v {
    flex: 1;
  }
}
</style>
