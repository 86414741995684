<template lang="pug">
    div.nav-box
      div.insurance-year
        span 保险年度：
          el-select(class="my-select insurance-box" v-model="years" @change="chengeYear")
            el-option(v-for="(item,index) in yearList" :key="index" :label="item.name" :value="item.value")
      p(v-for="(item,index) in navList" ) 
        routerLink(:to="item.path" v-if="item.path!=undefined" class="my-rlinka" :class="$route.path.indexOf(item.path)!==-1?'router-link-exact-active':''" replace) {{item.label}}
        span(class="span-spline" v-if="!item.label")
 </template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      navList: [
        {
          label: "承保大数据",
          path: "/underwriting_data",
        },
        {
          label: "",
        },
        {
          label: "理赔大数据",
          path: "/claim_data",
        },
        {
          label: "",
        },
        {
          label: "组织保障",
          path: "/organization",
        },
        {
          label: "",
        },
        {
          label: "资讯中心",
          path: "/advisory_center",
        },
        {
          label: "",
        },
        {
          label: "保险家园",
          path: "/insurance_home",
        },
        {
          label: "",
        },
        {
          label: "赔案中心",
          path: "/indemnity_center",
        },
        {
          label: "",
        },
        {
          label: "承保中心",
          path: "/underwriting_center",
        },
      ],
      years: 0,
      yearList: [],
    };
  },
  created() {
    this.yearList = [];
    for (let i = new Date().getFullYear()-2021; i > -1; i--) {
      this.yearList.push({
        name: (new Date().getFullYear() - i) + '年',
        value: (new Date().getFullYear() - i)
      })
    }
    this.years = new Date().getFullYear();
  },
  methods: {
    ...mapActions([
      "setYearAction"
    ]),
    onAdmin() {},
    onLogOut() {
    },
    onGoPage(path) {
      this.$router.push({ path: path });
    },
    chengeYear() {
      this.setYearAction(this.years);
    }
  },
};
</script>

<style lang="less" scoped>
.nav-box {
  height: 48px;
  line-height: 48px;
  width: 100%;
  background: rgba(7, 9, 79, 0.3);
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #2262b1;
  justify-content: center;
  position: relative;
  .insurance-year {
    position: absolute;
    left: 1.25rem;
    color: #0493cd;
    font-weight: 600;
    .insurance-box {
      width: 8rem;
      color: #0493cd;
    }
  }
  p {
    display: inherit;
    span:not(.span-spline) {
      cursor: pointer;
    }
    a {
      color: #2262b1;
    }
  }
  .span-spline {
    width: 18px;
    height: 29px;
    margin: 0 15px;
    background: url("../assets/icon/divline.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
  .my-rlinka {
    padding: 0 16px;
  }
  .my-rlinka:hover {
    color: #38d3ff;
    /* background: rgba(6, 19, 70, 0.3) url(../../../../../public/img/navbg1.png); */
  }
  .my-rlinka.router-link-exact-active {
    color: #38d3ff;
    /* background: rgba(6, 19, 70, 0.3) url(../../../../../public/img/navbg1.png); */
  }
  .router-link-active {
    background: url("../assets/icon/link-act-bb.png") bottom no-repeat;
    background-size: 100%;
  }
}
</style>
